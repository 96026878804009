<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row class="d-flex flex-nowrap">
          <v-col>
            <h3>{{ cardTitle }}</h3>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <!-- Sorok -->
        <v-row class="mb-5">
          <v-col cols="12">
            <v-row>
              <v-col class="py-0 my-0">
                <v-text-field
                  v-model="additionalNews.breakingNews.text"
                  label="Breaking news"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col class="py-0 my-0">
                <v-text-field
                  v-model="additionalNews.breakingNews.url"
                  label="Breaking news URL"
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 my-0">
                <v-text-field
                  v-model="additionalNews.event1.text"
                  label="Esemény 1. menü neve"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col class="py-0 my-0">
                <v-text-field
                  v-model="additionalNews.event1.url"
                  label="Esemény 1. menü URL"
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 my-0">
                <v-text-field
                  v-model="additionalNews.event2.text"
                  label="Esemény 2. menü neve"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col class="py-0 my-0">
                <v-text-field
                  v-model="additionalNews.event2.url"
                  label="Esemény 2. menü URL"
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="py-0 my-0">
                <v-menu
                        ref="m4sport.next_stream_date_picker"
                        v-model="m4sport.next_stream_date_picker"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                  >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                                v-model="m4sport.next_stream_date"
                                label='Következő "Jövünk" adás'
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="m4sport.next_stream_date"
                          no-title
                          scrollable
                          locale="hu-HU"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                                text
                                color="primary"
                                @click="m4sport.next_stream_date = null; 
                                        m4sport.next_stream_date_picker = false;
                                        m4sport.next_stream_time = null;
                                        m4sport.next_stream_time_picker = false"
                          >
                                Törlés
                          </v-btn>
                          <v-btn
                                text
                                color="primary"
                                @click="m4sport.next_stream_date_picker = false"
                          >
                                OK
                          </v-btn>
                        </v-date-picker>
                  </v-menu>
                </v-col>  
                <v-col cols="3" class="py-0 my-0">  
                  <v-menu
                        ref="m4sport.next_stream_time_picker"
                        v-model="m4sport.next_stream_time_picker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                  >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                                v-model="m4sport.next_stream_time"
                                label="Időpont"
                                prepend-icon="mdi-clock"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          :disabled="!m4sport.next_stream_date"
                          v-if="m4sport.next_stream_time_picker"
                          v-model="m4sport.next_stream_time"
                          format="24hr"
                          full-width
                          @click:minute="m4sport.next_stream_time_picker = false"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                                  text
                                  color="primary"
                                  @click="m4sport.next_stream_time = null;
                                          m4sport.next_stream_time_picker = false"
                            >
                                  Törlés
                            </v-btn>                           
                        </v-time-picker>
                  </v-menu>                 
              </v-col>
              <v-col cols="6" class="py-0 my-4">
                <v-text-field
                  v-model="m4sport.next_stream_url"
                  label="URL"
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>  
          </v-col>
        </v-row>
        <!-- <v-divider class="my-5"></v-divider> -->
        <!-- Címlap alatti banner -->
        <v-row
          class="mb-4"
        >
          <v-col>
            <b>Címlap alatti banner</b>  
            <div class="block">
                <v-row>
                  <v-col>
                    Template: <b>{{ getTemplateName(cimlapAlattiBanner.template) }}</b>
                  </v-col>
                  <v-col>                   
                    <span
                      v-if="cimlapAlattiBanner.template == 'banner_1'"
                    >
                      Bannerek:
                      <b v-if="cimlapAlattiBanner.banner_1_id"
                        >{{ getBannerByID(cimlapAlattiBanner.banner_1_id).name }},
                      </b>
                      <b v-else></b>
                    </span>
                  </v-col>
                  <v-spacer></v-spacer>
                  <div class="py-3">
                    <v-icon
                      color="primary"
                      class="mx-2"
                      small
                      @click="handleEditCABanner()"
                      >
                      mdi-pencil
                    </v-icon>
                  </div>
                </v-row>
            </div>
          </v-col>
        </v-row>

        <v-row
          v-for="(row, indexRow) in templateRows"
          :key="indexRow"
          class="mb-4"
        >
          <v-col v-for="(col, indexCol) in row.cols" :key="indexCol">
            <b>{{ row.name }}</b>
            <div
              v-for="(block, indexBlock) in col"
              :key="indexBlock"
              class="block"
              :class="[
                {
                  selected: isSelected(indexRow, indexCol, indexBlock),
                },
                { disabled: block.disabled },
              ]"
            >
              <v-row>
                <v-col>
                  Template: <b>{{ getTemplateName(block.template) }}</b>
                </v-col>
                <v-col>
                  <span
                    v-if="
                      block.template != 'banner_1' &&
                      block.template != 'banner_2'
                    "
                  >
                    Kategória:
                    <b>{{ getNewsCategoryByID(block.categoryId).name }}</b>
                  </span>
                  <span
                    v-if="
                      block.template == 'banner_1' ||
                      block.template == 'banner_2'
                    "
                  >
                    Bannerek:
                    <b v-if="block.banner_1_id"
                      >{{ getBannerByID(block.banner_1_id).name }},
                    </b>
                    <b v-if="block.banner_2_id">{{
                      getBannerByID(block.banner_2_id).name
                    }}</b>
                  </span>
                </v-col>

                <v-spacer></v-spacer>
                <div class="py-3">
                  <v-icon
                    v-if="!(modalIndexes && !block.disabled)"
                    color="primary"
                    :disabled="block.disabled"
                    class="ml-1"
                    small
                    @click="
                      handleEditBlock(indexRow, indexCol, indexBlock, block)
                    "
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    v-if="!(modalIndexes && !block.disabled)"
                    color="primary"
                    :disabled="block.disabled"
                    class="ml-1"
                    small
                    @click="handleDelete(indexRow, indexCol, indexBlock, block)"
                  >
                    mdi-delete
                  </v-icon>
                  <v-icon
                    color="primary"
                    :disabled="block.disabled"
                    class="mx-2"
                    small
                    @click="
                      handleSelectBlock(indexRow, indexCol, indexBlock, block)
                    "
                  >
                    <!-- flaticon2-sort -->

                    flaticon2-resize
                  </v-icon>
                </div>
              </v-row>
            </div>

            <v-layout justify-center>
              <v-btn
                color="primary"
                fab
                x-small
                dark
                @click="handleAddModal(indexRow, indexCol)"
              >
                <v-icon>flaticon2-add</v-icon>
              </v-btn>
            </v-layout>
          </v-col>
        </v-row>

        <v-dialog v-model="modalDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Törlés</v-card-title>
            <v-card-text class="headline"
              >Biztos törölni szeretnéd a szerkesztőből?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="handleCloseDeleteModal()">{{
                $t("FORMS.cancel")
              }}</v-btn>
              <v-btn color="primary" @click="deleteItemConfirm()">OK</v-btn>

              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="modal.active" max-width="650px" persistent>
          <v-card>
            <v-card-title class="headline">{{ modal.title }}</v-card-title>
            <v-card-text class="headline">
              <v-select
                v-if="modalModel"
                v-model="modalModel.template"
                :items="computedTemplateCollection"
                label="A blokk típusa"
                item-value="id"
                item-text="name"
                @change="
                  modalModel.bannerId = null;
                  modalModel.banner_1_id = null;
                  modalModel.banner_2_id = null;
                  modalModel.categoryId = null;
                "
              >
              </v-select>
              <v-autocomplete
                v-if="
                  modalModel &&
                  modalModel.template != 'banner_1' &&
                  modalModel.template != 'banner_2'
                "
                v-model="modalModel.categoryId"
                :items="computedNewsCategoryCollection"
                label="Kategória"
                item-value="id"
                item-text="name"
                @change="
                  modalModel.bannerId = null;
                  modalModel.banner_1_id = null;
                  modalModel.banner_2_id = null;
                "
              >
              </v-autocomplete>
              <v-select
                v-if="
                  modalModel &&
                  (modalModel.template == 'banner_1' ||
                    modalModel.template == 'banner_2')
                "
                v-model="modalModel.banner_1_id"
                :items="computedBannerCollection"
                label="Banner 1"
                item-value="id"
                item-text="name"
                @change="modalModel.categoryId = null"
              >
              </v-select>
              <v-select
                v-if="modalModel && modalModel.template == 'banner_2'"
                v-model="modalModel.banner_2_id"
                :items="computedBannerCollection"
                label="Banner 2"
                item-value="id"
                item-text="name"
                @change="modalModel.categoryId = null"
              >
              </v-select>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="handleCloseModal()">{{
                $t("FORMS.cancel")
              }}</v-btn>
              <v-btn color="primary" @click="handleAcceptModal()">OK</v-btn>

              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>  

        <v-dialog v-model="modalCABanner.active" max-width="650px" persistent>
          <v-card>
            <v-card-title class="headline">{{ modalCABanner.title }}</v-card-title>
            <v-card-text class="headline">
              <v-select
              v-model="modalCABannerModel"
              :items="computedCimlapAlattiBannerCollection"
              label="Banner 1"
              item-value="id"
              item-text="name"
              >
              </v-select>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="handleCloseCABannerModal()">{{
              $t("FORMS.cancel")
              }}</v-btn>
              <v-btn color="primary" @click="handleAcceptCABannerModal()">OK</v-btn>

              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog> 

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" elevation="2" @click="handleSaveForm()">
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SiteService from "@/core/services/site.service.js";
import ApiService from "@/core/services/api.service";

//export const PERMISSION_TO = "news.mainPageEditor.menu";

const INITIAL_MODAL_SETTINGS = {
  active: false,
  title: "Block módosítása",
  type: "edit",
};

const INITIAL_MODAL_CIMLAP_ALATTI_BANNER = {
  active: false,
  title: "Banner módosítása",
};

const INITIAL_SELECTED_BLOCK = {
  categoryId: null,
  template: null,
  bannerId: null,
  type: 1,
};

export default {
  name: "News",

  data() {
    return {
      loader: false,
      cardTitle: "Főoldali szerkesztő",

      indexToDelete: null,
      modalDelete: false,

      selectedBlock: Object.assign({}, INITIAL_SELECTED_BLOCK),

      modal: Object.assign({}, INITIAL_MODAL_SETTINGS),
      modalModel: null,
      modalIndexes: null,

      modalCABanner: Object.assign({}, INITIAL_MODAL_CIMLAP_ALATTI_BANNER),
      modalCABannerModel: null,

      templateCollection: [
        /*{
          id: "template_1_1",
          name: "1 hasábos blokk 1. típus",
          cols: 1,
        },
        {
          id: "template_1_2",
          name: "1 hasábos blokk 2. típus",
          cols: 1,
        },
        {
          id: "template_2_1",
          name: "2 hasábos blokk 1. típus",
          cols: 2,
        },
        {
          id: "template_2_2",
          name: "2 hasábos blokk 2. típus",
          cols: 2,
        },
        {
          id: "template_2_3",
          name: "2 hasábos blokk 3. típus",
          cols: 2,
        },
        {
          id: "template_2_4",
          name: "2 hasábos blokk 4. típus",
          cols: 2,
        },
        {
          id: "banner_2_5",
          name: "2 hasábos blokk 5. típus",
          cols: 2,
        },
        {
          id: "template_2_6",
          name: "2 hasábos blokk 6. típus",
          cols: 2,
        },
        {
          id: "banner_1",
          name: "1 hasábos banner",
          cols: 1,
        },
        {
          id: "banner_2",
          name: "2 hasábos banner",
          cols: 2,
        },*/
      ],

      additionalNews: {
        breakingNews: {
          text: "",
          url: "",
        },
        event1: {
          text: "",
          url: "",
        },
        event2: {
          text: "",
          url: "",
        },
      },
      
      m4sport: {
          next_stream_date: null,
          next_stream_date_picker: false,
          next_stream_time: null,
          next_stream_time_picker: false,
          next_stream_url: ""
      },

      cimlapAlattiBanner: {
          template: "banner_1",
          banner_1_id: null,
      },

      templateRows: [
        {
          type: "1",
          name: "Oldal teteje",
          cols: [
            [
              /*{
                categoryId: 12,
                template: "template_1_1",
              },

              {
                categoryId: 8,
                template: "banner_1",
                banner_1_id: 1,
              },
              {
                categoryId: 11,
                template: "banner_1",
                banner_1_id: 2,
              },*/
            ],
          ],
        },

        {
          type: "2",
          name: "Hasábok",
          cols: [
            [
              /*{
                categoryId: 16,
                template: "template_2_1",
              },
              {
                categoryId: 19,
                template: "template_2_4",
              },*/
            ],

            [
              /*{
                categoryId: 21,
                template: "template_2_3",
              },
              {
                categoryId: 3,
                template: "template_2_6",
              },
              {
                categoryId: 7,
                template: "template_2_2",
              },
              {
                categoryId: 15,
                template: "template_2_4",
              },*/
            ],
          ],
        },

        {
          type: "1",
          name: "Oldal alja",
          cols: [
            [
              /*{
                categoryId: 12,
                template: "template_1_2",
              },*/
            ],
          ],
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      "newsCategoryCollection",
      "bannerCollection",
      "statuses",
      "getNewsCategoryByID",
      "getBannerByID",
      "newsBlockTypeCollection",
    ]),

    computedTemplateCollection() {
      /*if (this.selectedBlock.type) {
        return this.templateCollection.filter(
          (item) => item.cols == this.selectedBlock.type
        );
      }
      return this.templateCollection;*/

      if (this.selectedBlock.type) {
        return this.newsBlockTypeCollection.filter(
          (item) => item.cols == this.selectedBlock.type
        );
      }
      return this.newsBlockTypeCollection;
    },

    computedCimlapAlattiBannerCollection() {
      let banners = this.bannerCollection.filter(
        (banner) => banner.type == 1
      );
      let collection = [];
      collection.push({id: null, name: 'Nincs kiválasztva'});
      if(banners.length > 0) {
        banners.forEach((item) => {
          collection.push({id: item.id, name: item.name});
        });
      }     
      //console.log(collection);
      return collection;
    },

    computedBannerCollection() {
      let bannerType = 1;
      if (this.modalModel.template == "banner_2") bannerType = 3;
      /*return this.bannerCollection.filter(
        (banner) => banner.type == bannerType
      );*/
      let banners = this.bannerCollection.filter(
        (banner) => banner.type == bannerType
      );
      let collection = [];
      collection.push({id: null, name: 'Nincs kiválasztva'});
      if(banners.length > 0) {
        banners.forEach((item) => {
          collection.push({id: item.id, name: item.name});
        });
      }     
      //console.log(collection);
      return collection;
    },

    computedNewsCategoryCollection() {
      let categoryIds = [];
      this.templateRows.forEach((row) =>
        row.cols.forEach((column) =>
          column.forEach((template) => {
            if (
              template.template != "banner_1" &&
              template.template != "banner_2" &&
              !categoryIds.includes(template.categoryId) &&
              this.modalModel.categoryId != template.categoryId
            ) {
              categoryIds.push(template.categoryId);
            }
          })
        )
      );

      return this.newsCategoryCollection.filter(
        (category) => !categoryIds.includes(category.id)
      );
    },
  },

  methods: {
    ...mapActions(["fetchNewsCategory", "fetchBanner", "fetchNewsBlockType"]),

    handleCloseCABannerModal() {
      this.modalCABanner = Object.assign({}, INITIAL_MODAL_CIMLAP_ALATTI_BANNER);
      this.modalCABannerModel = null;
    },

    handleEditCABanner() {
      this.modalCABannerModel = this.cimlapAlattiBanner.banner_1_id;
      this.modalCABanner.active = true
    },

    handleAcceptCABannerModal() {
      this.cimlapAlattiBanner.banner_1_id = this.modalCABannerModel;
      this.handleCloseCABannerModal();
    },

    handleCloseModal() {
      this.modal = Object.assign({}, INITIAL_MODAL_SETTINGS);
      this.modalModel = null;
      this.resetSelected();
      this.resetDisableAll();
    },

    handleEditBlock(indexRow, indexCol, indexBlock, block) {
      this.modalIndexes = {
        indexRow,
        indexCol,
        indexBlock,
      };

      this.selectedBlock = block;
      this.modalModel = Object.assign({}, block);

      this.selectedBlock.type = this.templateRows[indexRow].type;
      (this.modal.type = "edit"), (this.modal.active = true);
    },

    handleAddModal(indexRow, indexCol) {
      this.modalIndexes = {
        indexRow,
        indexCol,
      };

      this.modalModel = Object.assign({}, INITIAL_SELECTED_BLOCK);

      this.selectedBlock.type = this.templateRows[indexRow].type;
      this.modal.type = "create";
      this.modal.active = true;
      this.modal.title = "Blokk létrehozása";
    },

    handleAcceptModal() {
      if (this.modal.type == "edit") {
        this.templateRows[this.modalIndexes.indexRow].cols[
          this.modalIndexes.indexCol
        ][this.modalIndexes.indexBlock] = this.modalModel;
      }

      if (this.modal.type == "create") {
        this.templateRows[this.modalIndexes.indexRow].cols[
          this.modalIndexes.indexCol
        ].push(this.modalModel);
      }
      this.selectedBlock = {};
      this.handleCloseModal();
    },

    handleCloseDeleteModal() {
      this.modalDelete = false;
      this.resetSelected();
      this.resetDisableAll();
    },

    handleDelete(indexRow, indexCol, indexBlock) {
      this.modalIndexes = {
        indexRow,
        indexCol,
        indexBlock,
      };
      this.modalDelete = true;
    },

    deleteItemConfirm() {
      this.templateRows[this.modalIndexes.indexRow].cols[
        this.modalIndexes.indexCol
      ].splice(this.modalIndexes.indexBlock, 1);

      this.handleCloseDeleteModal();
    },

    getTemplateById(id) {
      return this.newsBlockTypeCollection.find((item) => item.id == id);
    },

    getTemplateName(id) {
      let item = this.getTemplateById(id);
      if (typeof item !== "undefined") {
        return typeof item.name !== "undefined" ? item.name : item.id;
      } else {
        return "Nincs adat!";
      }
    },

    handleSelectBlock(indexRow, indexCol, indexBlock, block) {
      if (!this.modalIndexes) {
        this.modalIndexes = {
          indexRow,
          indexCol,
          indexBlock,
        };

        this.selectedBlock = block;
        this.selectedBlock.selected = true;
        this.setDisableAll();

        this.templateRows[indexRow].cols.forEach((col) => {
          col.forEach((block) => {
            block.disabled = false;
          });
        });
      } else {
        this.templateRows[this.modalIndexes.indexRow].cols[
          this.modalIndexes.indexCol
        ][this.modalIndexes.indexBlock] = block;

        this.templateRows[indexRow].cols[indexCol][indexBlock] =
          this.selectedBlock;

        this.resetSelected();
        this.resetDisableAll();
      }
    },

    handleAddNew(indexRow, indexCol) {
      this.templateRows[indexRow].cols[indexCol].push(INITIAL_SELECTED_BLOCK);
    },

    resetSelected() {
      this.selectedBlock = Object.assign({}, INITIAL_SELECTED_BLOCK);

      this.modalIndexes = null;
    },

    setDisableAll() {
      this.templateRows.forEach((row) => {
        row.cols.forEach((col) => {
          col.forEach((block) => {
            block.disabled = true;
          });
        });
      });
    },

    resetDisableAll() {
      this.templateRows.forEach((row) => {
        row.cols.forEach((col) => {
          col.forEach((block) => {
            block.disabled = false;
          });
        });
      });
    },

    isSelected(indexRow, indexCol, indexBlock) {
      return (
        this.modalIndexes &&
        this.modalIndexes.indexRow == indexRow &&
        this.modalIndexes.indexCol == indexCol &&
        this.modalIndexes.indexBlock == indexBlock
      );
    },

    handleSaveForm() {
      this.loader = true;
      ApiService.post(
        "news/admin/" +
          SiteService.getActiveSiteId() +
          "/news-main-page-editor/saveForm",
        {
          cimlapAlattiBanner: this.cimlapAlattiBanner,
          additionalNews: this.additionalNews,
          templateRows: this.templateRows,
          m4sport: this.m4sport
        }
      ).then(() => {
        this.loader = false;
      });
    },

    fetchModel() {
      this.fetchNewsCategory();
      this.fetchBanner();
      this.fetchNewsBlockType();
    },

    getMainPageEditorData() {
      this.loader = true;
      ApiService.get(
        "news/admin/" +
          SiteService.getActiveSiteId() +
          "/news-main-page-editor/show"
      ).then((response) => {
        if (typeof response.data.option_content != "undefined") {
          let option_content = response.data.option_content;
          if (typeof option_content.templateRows != "undefined") {
            this.templateRows = option_content.templateRows;
          }
          if (typeof option_content.cimlapAlattiBanner != "undefined") {
            this.cimlapAlattiBanner = option_content.cimlapAlattiBanner;
          }
          if (typeof option_content.additionalNews != "undefined") {
            if (
              typeof option_content.additionalNews.breakingNews != "undefined"
            ) {
              this.additionalNews.breakingNews =
                option_content.additionalNews.breakingNews;
            }
            if (typeof option_content.additionalNews.event1 != "undefined") {
              this.additionalNews.event1 = option_content.additionalNews.event1;
            }
            if (typeof option_content.additionalNews.event2 != "undefined") {
              this.additionalNews.event2 = option_content.additionalNews.event2;
            }
          }
          if (typeof option_content.m4sport != "undefined") {
              if (typeof option_content.m4sport.next_stream_date != "undefined") {
                this.m4sport.next_stream_date = option_content.m4sport.next_stream_date;
              }
              if (typeof option_content.m4sport.next_stream_time != "undefined") {
                this.m4sport.next_stream_time = option_content.m4sport.next_stream_time;
              }
              if (typeof option_content.m4sport.next_stream_url != "undefined") {
                this.m4sport.next_stream_url = option_content.m4sport.next_stream_url;
              }
          }
        }
        this.loader = false;
      });
    },
  },

  mounted() {
    this.loadingTable = true;
    this.fetchModel();
    this.getMainPageEditorData();
  },
};
</script>

<style scoped>
.block {
  border: solid 1px #ccc;
  margin: 0.5rem 0;
  padding: 1rem;
}

.block:hover {
  background-color: #f3f6f9;
}

.selected {
  background-color: #f3f6f9;
  border: solid 1px #e33354;
}

.disabled {
  color: #9e9e9e;
}
</style>
